// islands/AccordionControl.tsx
import { allAccordionOpen } from "./globalSignals.ts";

export default function AccordionControl() {
  const toggleAllAccordions = () => {
    // Wechsle den Zustand aller Accordions
    allAccordionOpen.value = !allAccordionOpen.value;
  };

  return (
    <div class="flex justify-end mb-4">
      <button
        onClick={toggleAllAccordions}
        class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md"
      >
        {allAccordionOpen.value ? "Alles zuklappen" : "Alles aufklappen"}
      </button>
    </div>
  );
}
